
import React, {useState, useContext } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";
import { borderExtension } from "../extensions/QRextensions";
import LogoCopy from "./LogoCopy";
import extensionOptions from "./GeneratedQR"
import * as API from '../utils/APICalls'

import axios from "axios";
import QRCodeStyling from "qr-code-styling";
import ThemeColorPicker from "./ThemeColorPicker";
import { Button, Tooltip } from "@mui/material";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import LocalFireDepartment from "@mui/icons-material/LocalFireDepartment";
import useWindowDimensions from "../hooks/windowDimensions";

const UserNavBar = (props) => {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies([]);

    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const { appData, updateAppData, modalOpen,
        appTheme, setAppTheme,
        setIsLoggedIn, userData,setUserData  } = useContext(AppContext);
    const { height, width } = useWindowDimensions();
    
    const [showThemePicker, setShowThemePicker ] = useState(false)

    const handleSave = async (e) => {
        e.preventDefault();

        const qrCode = new QRCodeStyling(qrCodeData);
        // qrCode?.applyExtension(borderExtension(extensionOptions))
        await qrCode?.download({ name: "QauraQR", extension: "png" });
        // try {
        //     const response = await axios.post("API_endpoint", qrCodeData);
        //     console.log(response.data);
        // } catch (error) {
        //     console.error(error);
        // }
        };

        const handleLogout = async()=>{
            try{

                setIsLoggedIn(false)
                // setRoom()
                let data = await API.userByID(userData?._id)
                let user = await API.updateUser({...data,isOnline:false})
                removeCookie("token");
                setUserData()
                //? setUserData('')
            //?    localStorage.setItem('QauRaUser', user)
    
    
                localStorage.setItem('QauRaUser', '')
                // updateRoom('user_exit')
                // hmsActions.leave();
            }catch(err){
                console.log('[Logout]:', err)
            }
        }
  return (
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'sticky',
        top: '0', 
        left: '0',
        flex:1,
        padding:'.5rem',
        backgroundColor:"rgba(0,0,0,.4)",
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0) 0% ,rgba(255,255,255,0.7) 60%, rgba(255,255,255,0.8) 100%)`,
        //  background: 'linear-gradient(45deg,rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3)10%, rgba(255,255,255,0.4)15%, rgba(255,255,255,0.2)23%, rgba(255,255,255,0.2)45%, rgba(255,255,255,0.3)50% ,rgba(255,255,255,0.4)53%, rgba(255,255,255,0.1)83%, rgba(255,255,255,0.3)90%, rgba(255,255,255,0.2)93%, rgba(255,255,255,0.7)94%, rgba(255,255,255,0.3)96%, rgba(255,255,255,0.1)100%)',backdropFilter: 'blur(15px)',
        width: '100%',
        maxWidth: '100vw',
        minWidth: '100%',
        height:"7dvh",
        minHeight: "72px",
        color:"#fff", 
        alignItems:'center', 
        justifyItems:"end", 
        boxShadow:'0px 0px 10px 5px rgba(0,0,0,0.7)',
        backdropFilter: 'blur(3px)',
        WebkitBackdropFilter: 'blur(3px)',
        // , inset -5px -10px 10px 10px rgba(221, 253, 251, 1)',
        border: "none",
        borderBottom: '3px ridge #868686',
        justifyContent: 'space-evenly',
        alignItems:'center',
        zIndex:'1000'

        }}
        className= "color-grad-blue-border" id="navbar"
        >
        <div style={{display:'flex', flexDirection:'row', flex:1, maxWidth:'200px'}}> 
          <button className="button save-button" 
            style={{
                fontSize:'0.75rem',
                display:'flex', 
                flexDirection:'column',
                color:"#000000",
                // color:"#00FF00",
                height: 'auto', 
                padding:'0.25rem',
                // borderRadius:'0 5px',
                position: 'absolute',
                top:'10px',
                left: '10px', 
                alignItems:'center'}}
                // onClick={()=>{setModalOpen(!modalOpen)}}
                onClick={()=>{
                    handleLogout()
                   window.location.assign("/")
                }}
                type="submit" >
                <img src={'/assets/home.svg'} style={{height:'4vh', alignSelf: 'center'}} alt="Logout/home"/>
            logout
            </button>
            
        </div> 
        {/* <Tooltip title="Scan interactions and followers (coming soon)" >
                <Button  size='small' variant="outlined" className="button save-button "
                    sx={{position: 'absolute', bottom: '0rem', left:  width <=500 ? '0rem':'20%'}}
                    onClick={()=> {
                        // props.setShowMetrics(!props.showMetrics)
                        updateAppData({
                            modalOpen:!modalOpen,
                            modalView:'metrics'
                            })
                    }}
                    >
                    <TroubleshootIcon />Metrics
                </Button>
            </Tooltip> */}

        <LogoCopy />
         <div style={{display: 'flex',flex:'1', maxWidth:'200px', flexDirection: 'column', gap: 5,
                alignSelf: 'right', margin:'0',right: '25px',justifyContent:'space-between'}}>
            {/* <button 
                className="button save-button blink"
                style={{display: 'flex',padding:'0.25rem'}}
                type="submit" 
                onClick={()=>{
                    // window.location.assign('/profile')
                    updateAppData({
                        modalOpen:!modalOpen,
                        modalView:'settings'
                    })
                }}
               >
                <img src={'/assets/dashboard.svg'} style={{height:'25px', alignSelf: 'center'}} alt="dashboard"/>
            </button> */}

            {/* <Tooltip title="Quick action links (coming soon)" >
                <Button  size='small' variant="outlined" className="button save-button "
                    sx={{position: 'absolute', bottom: '0rem', right: width <=500 ? '1rem':'20%'}} 
                    onClick={()=>{ 
                        // props.setShowMetrics(!props.showMetrics)
                        updateAppData({
                            modalOpen:!modalOpen,
                            modalView:'hot-links'
                            })
                        }}>
                    Hot Links <LocalFireDepartment />
                </Button>
            </Tooltip> */}
            <button 
                className="button save-button "
                type="submit" 
                onClick={()=>{
                    updateAppData({
                        modalOpen:!modalOpen,
                        modalView:'user-menu'
                    })
                }}
                style={{
                    display: 'flex', 
                    position:'absolute',
                    top:'0.5rem', 
                    right:'1.5rem'}}>
                {/* <img src={'/assets/bars-solid.svg'} style={{height:'35px', alignSelf: 'center'}} alt="menu"/> */}
                <img className="settings-gear"
                src={'/assets/SettingsGear.svg'} style={{height:'35px', alignSelf: 'center'}} alt="menu"/>
            </button>
           
        </div>
        
        {/* <div style={{flex:'1', maxWidth:'200px',alignSelf: 'right'}}>
            <button 
                className="button save-button blink"
                type="submit" 
                onClick={()=>{
                    updateAppData({
                        modalOpen:!modalOpen,
                        modalView:'user-menu'
                    })
                }}
                style={{display: 'flex',position: 'absolute', margin:'0',right: '25px', alignSelf: 'right'}}>
                <img src={'/assets/bars-solid.svg'} style={{height:'20px', alignSelf: 'center'}} alt="menu"/>
            </button>
        </div> */}
    </div>
  )
}

export default UserNavBar